import { createStore } from 'vuex';
import auth from './modules/auth';

export default createStore({
  actions: {
    fetch_all ({ dispatch }) {
    },
  },
  modules: {
    auth,
  },
  strict: process.env.NODE_ENV !== 'production',
});