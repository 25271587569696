import { createRouter, createWebHistory } from 'vue-router';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Slider'),
  },
  {
    path: '/slider',
    name: 'Slider',
    component: () => import('../views/Slider'),
  },
  {
    path: '/clock',
    name: 'Clock',
    component: () => import('../views/EinsteinClock'),
  },
  {
    path: '/spiral',
    name: 'Spiral',
    component: () => import('../views/Spiral'),
  },
  {
    path: '/waves',
    name: 'Waves',
    component: () => import('../views/Waves'),
  },
  {
    path: '/clipPath',
    name: 'ClipPath',
    component: () => import('../views/ClipPath'),
  },
  {
    path: '/pictures',
    name: 'Pictures',
    component: () => import('../views/Pictures'),
  },
  {
    path: '/moveBackground',
    name: 'MoveBackground',
    component: () => import('../views/MoveBackground'),
  },
  {
    path: '/photo',
    name: 'Photo',
    component: () => import('../views/Photo'),
  },
  {
    path: '/incline',
    name: 'Incline',
    component: () => import('../views/Incline'),
  },
  {
    path: '/dots',
    name: 'Dots',
    component: () => import('../views/Squares'),
  },
  {
    path: '/slidingTabs',
    name: 'slidingTabs',
    component: () => import('../views/SlidingTabs'),
  },
  {
    path: '/carousel',
    name: 'Carousel',
    component: () => import('../views/Carousel'),
  },
  {
    path: '/goldenRatio',
    name: 'GoldenRatio',
    component: () => import('../views/GoldenRatio'),
  },
  {
    path: '/scramble',
    name: 'Scramble',
    component: () => import('../views/Scramble'),
  },
  {
    path: '/interactiveMenu',
    name: 'InteractiveMenu',
    component: () => import('../views/InteractiveMenus'),
  },
  {
    path: '/profileCard',
    name: 'ProfileCard',
    component: () => import('../views/ProfileCard'),
  },
  {
    path: '/skills',
    name: 'Skills',
    component: () => import('../views/Skills'),
  },
  {
    path: '/reflection',
    name: 'Reflection',
    component: () => import('../views/Reflection'),
  },
  {
    path: '/firstSlide',
    name: 'firstSlide',
    component: () => import('../views/FirstSlide'),
  },
  {
    path: '/magic',
    name: 'Magic',
    component: () => import('../views/Magic'),
  },
  {
    path: '/sideMenu',
    name: 'SideMenu',
    component: () => import('../views/SideMenu'),
  },
  {
    path: '/chatModal',
    name: 'ChatModal',
    component: () => import('../views/ChatModal'),
  },
  {
    path: '/theEnd',
    name: 'TheEnd',
    component: () => import('../views/TheEnd'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;