const state = {
  authUser: {},
};


const mutations = {
  setCurrentUser (state, user) {
    state.authUser = user;
  },
  setUserAuth (state, user) {
    state.authUser = user;
  },
  resetUser (state) {
    state.authUser = {};
  },
};


const actions = {
  save_user_info ({ commit }, user) {
    commit('setUserAuth', user);
  }
};


const getters = {
  authUser: state => state.authUser,
  authUserID: state => state.authUser.uid,
};


export default {
  //namespaced: true,
  state,
  mutations,
  actions,
  getters
};