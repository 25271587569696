<template>
<div id="overlay__wrapper">
  <div class="overlay__close-button" @click="closeOverlay()">&times;</div>
  <div class="overlay__content">
    <div id="content-side__left">
      <div
        class="link"
        v-for="(link, index) in links_left"
        :key="index"
        @click="navigateTo(link)">
        <div class="link__text">{{ link.label }}</div>
      </div>
    </div>

    <div id="content-side__middle">
      <div
        class="link"
        v-for="(link, index) in links_middle"
        :key="index"
        @click="navigateTo(link)">
        <div class="link__text">{{ link.label }}</div>
      </div>
    </div>

    <div id="content-side__right">
      <div
        class="link"
        v-for="(link, index) in links_right"
        :key="index"
        @click="navigateTo(link)">
        <div class="link__text">{{ link.label }}</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'MenuLinks',
  data: () => ({
    links_left: [
      { to: 'reflection', label: 'reflection' },
      { to: 'clipPath', label: 'clipPath' },
      { to: 'waves', label: 'waves' },
      { to: 'clock', label: 'clock' },
      { to: 'spiral', label: 'spiral' },
      { to: 'profileCard', label: 'profileCard' },
      { to: 'scramble', label: 'scramble' },
      { to: 'skills', label: 'skills' },
      { to: 'magic', label: 'magic' },
      { to: 'sideMenu', label: 'sideMenu' },
      { to: 'chatModal', label: 'chatModal' },
    ],
    links_middle: [
      { to: 'interactiveMenu', label: 'interactiveMenu' },
      { to: 'photo', label: 'photo' },
      { to: 'moveBackground', label: 'moveBackground' },
      { to: 'pictures', label: 'pictures' },
      { to: 'incline', label: 'incline' },
      { to: 'goldenRatio', label: 'goldenRatio' },
      { to: 'carousel', label: 'carousel' },
      { to: 'slidingTabs', label: 'slidingTabs' },
      { to: 'firstSlide', label: 'firstSlide' },
      { to: 'dots', label: 'dots' },
      { to: 'theEnd', label: 'theEnd' },
    ],
    links_right: [
      { to: 'slider', label: 'slider' },
    ],
  }),
  mounted () {
    // document.addEventListener('keydown', event => {
    //   if (event.key === 'Z') {
    //     console.log('keydown - Z');
    //     document.getElementById('overlay__wrapper').style.height = '100%';
    //   }
    //   if (event.key === 'Escape') {
    //     console.log('keydown - esc');
    //     document.getElementById('overlay__wrapper').style.height = '0%';
    //   }
    // });
  },
  methods: {
    closeOverlay () {
      document.getElementById('overlay__wrapper').style.height = '0%';
    },
    navigateTo (link) {
      this.$router.push('/' + link.to).catch(() => {});
    },
  },
};
</script>

<style lang="stylus" scoped>
#overlay__wrapper
  height 0
  width 100%
  position fixed
  z-index 1000000
  left 0
  top 0
  background rgba(0, 0, 0, 0.9)
  overflow-x hidden
  transition 500ms
  display flex
  align-items center
  justify-content center
  .overlay__close-button
    color #818181
    position absolute
    top 20px
    right 45px
    font-size 60px
    cursor pointer
    transition 300ms
  .overlay__close-button:hover
    color white
  .overlay__content
    width 80%
    text-align center
    display flex
    align-items center
    justify-content space-evenly
    .link
      text-decoration none
      font 400 20px Averta, monospace
      display block
      padding 8px
      color #818181
      transition 300ms
    .link:hover, .link:focus
      color white
</style>